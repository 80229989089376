import './ListComponent.css'

interface ListComponentProps {
  time: string
  displayName: string
}

function ListComponent(props: ListComponentProps) {
  const { time, displayName } = props

  const generateTimeStyle = () => {
    return time.includes("4:20") && "time-4-20"
  }

  return (
    <div className="list__component">
      <p>
        Happy 420 from <span className="listcomponent__name">{displayName}</span> at <span className={`${generateTimeStyle()}`}>{time}</span>
      </p>
    </div>
  )
}

export default ListComponent
