import { initializeApp } from 'firebase/app'
import { addDoc, collection, getDocs, getFirestore } from 'firebase/firestore'

import { SubmissionModel } from '../models/submission.interface'
import { loadingSpinnerSubject$ } from './../components/LoadingSpinner/LoadingSpinner'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

export const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)

export const addSubmission = async (submission: SubmissionModel) => {
  console.log(firebaseConfig)
  try {
    await addDoc(collection(db, "submissions"), {
      displayName: submission.displayName,
      dateSubmitted: submission.dateSubmitted,
    })
  } catch (e) {
    console.error("Error adding document: ", e)
  }
}

export const getAllDocs = async () => {
  loadingSpinnerSubject$.next(true)
  const querySnapshot = await getDocs(collection(db, "submissions"))
  const data: SubmissionModel[] = []
  querySnapshot.forEach((doc) => {
    data.push(doc.data() as SubmissionModel)
  })
  setTimeout(() => {
    loadingSpinnerSubject$.next(false)
  }, 500)
  return data || "error"
}
