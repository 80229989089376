import './Clock.css'

import { useEffect, useState } from 'react'

import { getCurrentTime } from '../utils/getCurrentTime'

function Clock() {
  const [clockState, setClockState] = useState<string>()

  useEffect(() => {
    const interval = setInterval(() => {
      setClockState(getCurrentTime())
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className="Clock">
      <h1>{clockState}</h1>
    </div>
  )
}

export default Clock
