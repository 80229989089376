export const getCurrentTime = (showDate?: boolean) => {
  const date = new Date()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  const ampm = hours >= 12 ? "PM" : "AM"
  const hour = hours % 12
  // get today's date
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  const time = `${hour}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds} ${ampm} ${showDate ? ` on ${day}/${month}/${year}` : ""}`
  return time
}
