import './LoadingSpinner.css'

import { useEffect, useState } from 'react'
import { Subject } from 'rxjs'

import LoadingSpinnerIcon from '../../assets/loadingspinner.svg'

export const loadingSpinnerSubject$ = new Subject<boolean>()

function LoadingSpinner() {
  const [openState, setOpenState] = useState<boolean>(false)

  useEffect(() => {
    const loadingSpinnerSub = loadingSpinnerSubject$.subscribe({
      next: (value) => {
        setOpenState(value)
      },
    })

    return () => {
      loadingSpinnerSub.unsubscribe()
    }
  }, [])

  const LoadingSpinnerComponent = () => {
    return (
      <div className="loadingspinner__backdrop">
        <img src={LoadingSpinnerIcon} alt="load-spinner" className="loadingspinner" />
      </div>
    )
  }

  return <>{openState && <LoadingSpinnerComponent />}</>
}

export default LoadingSpinner
