import './MainInput.css'

import React, { useEffect, useState } from 'react'
import { Subject } from 'rxjs'

import { addSubmission, getAllDocs } from '../../firebase'
import { SubmissionModel } from '../../models/submission.interface'
import ListComponent from '../ListComponent/ListComponent'
import { getCurrentTime } from '../utils/getCurrentTime'

export const refreshSubmissions$ = new Subject<boolean>()

function MainInput() {
  const [displayNameState, setDisplayNameState] = useState<string>("")
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [submissions, setSubmissions] = useState<SubmissionModel[]>([])

  useEffect(() => {
    getAllData()
  }, [])

  useEffect(() => {
    const dataRefreshSub = refreshSubmissions$.subscribe({
      next: (value) => value && getAllData(),
    })

    return () => {
      dataRefreshSub.unsubscribe()
    }
  }, [])

  const getAllData = async () => {
    const data = await getAllDocs()
    setSubmissions(data)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    addSubmission({ displayName: displayNameState, dateSubmitted: getCurrentTime(true) })
    refreshSubmissions$.next(true)
  }

  return (
    <div className="maininput__container">
      <div className="submission-score-container">
        <div>
          <form onSubmit={handleSubmit}>
            <h2>Happy 420 from:</h2>
            <div className="input__container">
              <input
                type="text"
                placeholder="display name"
                required
                onChange={(e) => {
                  setDisplayNameState(e.target.value)
                }}
                className="displayname__input"
                maxLength={15}
              />
              <button type="submit" className="submit__button">
                Submit
              </button>
            </div>
          </form>
          <div className="submission__list">
            {submissions.map((submission, i) => {
              return <ListComponent key={i} displayName={submission.displayName} time={submission.dateSubmitted} />
            })}
          </div>
        </div>
        <div className="scoreBoard">
          <h2>Scores:</h2>
          <input
            type="text"
            placeholder="Search Scoring Players"
            required
            onChange={(e) => {
              setSearchQuery(e.target.value)
            }}
            className="displayname__input score"
            maxLength={15}
          />
          {submissions
            .filter((submission) => submission.dateSubmitted.includes("4:20") && submission.displayName.includes(searchQuery))
            .map((submissions, i) => {
              return <ListComponent key={i} displayName={submissions.displayName} time={submissions.dateSubmitted} />
            })}
          <h2> {searchQuery && submissions.filter((submission) => submission.dateSubmitted.includes("4:20") && submission.displayName.includes(searchQuery)).length + " points"}</h2>
        </div>
      </div>
    </div>
  )
}

export default MainInput
