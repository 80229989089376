import './Main.css'

import { useEffect, useState } from 'react'

import Clock from '../../components/Clock/Clock'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import MainInput from '../../components/MainInput/MainInput'

function Main() {
  const [password, setPassword] = useState("")
  const passwordConfirm: string = "geefa420"

  useEffect(() => {
    if (localStorage.getItem("password") === passwordConfirm) {
      setPassword(passwordConfirm)
    }
  }, [])

  return (
    <div className="main">
      {password === passwordConfirm ? (
        <>
          <Clock />
          <MainInput />
          <LoadingSpinner />
        </>
      ) : (
        <div>
          <h1>What if I told you there is an app.... on the Maaaarket ??</h1>
          <p style={{ marginTop: "200px" }}>Type Password</p>
          <input
            type="text"
            onChange={(e) => {
              localStorage.setItem("password", e.target.value)
              setPassword(e.target.value)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default Main
